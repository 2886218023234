import { getCurrentPageIndex } from './getCurrentPageIndex';

export function getArticlePosition({
  index,
  initialPageSize,
  pageSize,
  articleListLength,
  hasMorePages,
}: {
  index: number;
  initialPageSize: number;
  pageSize: number;
  articleListLength: number;
  hasMorePages: boolean;
}): {
  isLastArticleOfList: boolean;
  isArticleWithInBetween: boolean;
} {
  const currentPageIndex = getCurrentPageIndex(index, initialPageSize, pageSize);

  const isLastArticleOfList = index === articleListLength - 1;
  const isLastArticle = isLastArticleOfList && !hasMorePages;
  const isLastArticleOfFirstPage = index === initialPageSize - 1;
  const isLastArticleOfExtraPage = index === pageSize * currentPageIndex + initialPageSize - 1;
  const isLastArticleOfPage = currentPageIndex === 0 ? isLastArticleOfFirstPage : isLastArticleOfExtraPage;

  return {
    isLastArticleOfList,
    isArticleWithInBetween: isLastArticleOfPage || isLastArticle,
  };
}
