import type { TStoryblock } from '@hubcms/domain-storyblock';

import { calculateExtendedSideContentRowSpan } from './calculateExtendedSideContentRowSpan';

export function getSideContentRowSpan({
  initialPageSize,
  currentPageIndex,
  articleListSize,
  pageSize,
  hasPrependChildren,
  getAdFormatForPage,
  asideRecirculationStoryblocks,
}: {
  initialPageSize: number;
  currentPageIndex: number;
  articleListSize: number;
  pageSize: number;
  hasPrependChildren: boolean;
  getAdFormatForPage: (index: number) => string | null;
  asideRecirculationStoryblocks: TStoryblock[];
}) {
  const listSize = articleListSize + (currentPageIndex === 0 && hasPrependChildren ? 1 : 0);
  const initialSideContentRowSpan = currentPageIndex === 0 ? initialPageSize + (hasPrependChildren ? 1 : 0) : pageSize;

  const rowSpan =
    initialSideContentRowSpan +
    calculateExtendedSideContentRowSpan(
      {
        pageSize,
        nextPageIndex: currentPageIndex + 1,
        maxPageIndex: Math.ceil((articleListSize - initialPageSize) / pageSize),
      },
      getAdFormatForPage,
      asideRecirculationStoryblocks,
    );

  return Math.min(rowSpan, listSize);
}
