import type { StoryHeaderData } from '@hubcms/domain-story';
import type { StoryElement } from '@hubcms/domain-story-elements';

export type TagPageTemplateData = {
  template: 'tag';
  storyHeaderData: StoryHeaderData;
  bodyElements: StoryElement[];
};

export function createTagPageTemplateData({
  storyHeaderData = {},
  bodyElements = [],
}: Partial<Omit<TagPageTemplateData, 'template'>> = {}): TagPageTemplateData {
  return {
    template: 'tag',
    storyHeaderData,
    bodyElements,
  };
}
