type PartitionedArray<T> = [included: T[], excluded: T[]];

export function partitionArray<T>(items: T[], filter: (item: T, idx: number, arr: T[]) => boolean): PartitionedArray<T> {
  return items.reduce<PartitionedArray<T>>(
    // Disabling eslit here as reduce is a standard JS function with more than 3 params
    // eslint-disable-next-line max-params
    ([included, excluded], item, idx, arr) => {
      if (filter(item, idx, arr)) {
        return [included.concat(item), excluded];
      }
      return [included, excluded.concat(item)];
    },
    [[], []],
  );
}
