import type { TStoryblock } from '@hubcms/domain-storyblock';

export function calculateExtendedSideContentRowSpan(
  pageOptions: {
    nextPageIndex: number;
    pageSize: number;
    maxPageIndex: number;
  },
  getAdFormatForPage: (index: number) => string | null,
  asideRecirculationStoryblocks: TStoryblock[],
): number {
  const nextPageHasAd = !!getAdFormatForPage(pageOptions.nextPageIndex);
  const nextPageHasRecirculation = !!asideRecirculationStoryblocks.slice(
    pageOptions.nextPageIndex,
    pageOptions.nextPageIndex + 1,
  )[0];
  if (nextPageHasAd || nextPageHasRecirculation) {
    return 0;
  }
  if (pageOptions.nextPageIndex < pageOptions.maxPageIndex) {
    return (
      pageOptions.pageSize +
      calculateExtendedSideContentRowSpan(
        {
          ...pageOptions,
          nextPageIndex: pageOptions.nextPageIndex + 1,
        },
        getAdFormatForPage,
        asideRecirculationStoryblocks,
      )
    );
  }
  return pageOptions.pageSize;
}
