export type MostRecentTemplateData = {
  template: 'most-recent';
  title: string;
};

export function createMostRecentTemplateData({
  title = '',
}: Partial<Pick<MostRecentTemplateData, 'title'>> = {}): MostRecentTemplateData {
  return {
    template: 'most-recent',
    title,
  };
}
