import { createTrackingClickHandler } from '@hubcms/data-access-tracking';
import type { MySectionTemplateData } from '@hubcms/domain-section';
import { updateMySectionCookies } from '@hubcms/utils-my-section';
import { createSelectSectionEvent } from '@hubcms/utils-tracking';
import { useCallback, useMemo } from 'react';
import type { SubSection } from '../domain/my-section-page-props';
import { MySectionPageList } from './MySectionPageList';
import { MySectionPageTiles } from './MySectionPageTiles';

type MySectionPageTemplateProps = {
  templateData: MySectionTemplateData;
};

function MySectionPageTemplate({ templateData }: MySectionPageTemplateProps) {
  const MySectionPageGroup = useMemo(() => {
    switch (templateData.layout) {
      case 'tiles':
        return MySectionPageTiles;
      case 'list':
      default:
        return MySectionPageList;
    }
  }, [templateData.layout]);

  const updateCookies = useCallback(
    (section: SubSection) => {
      // update cookies
      updateMySectionCookies({
        sectionUrl: templateData.overviewPageUrl ?? document.location.href,
        chosenPreferenceUrl: section.url,
        storeMySectionSelectionInCookie: templateData.storeMySectionSelectionInCookie,
        type: templateData.mySectionType,
        identifierLabel: templateData.identifierLabel,
        identifierValue: section.identifierValue,
        selectionName: section.name,
      });
    },
    [templateData],
  );

  const createSelectSectionHandler = useCallback(
    ({ section, letter }: { section: SubSection; letter?: string }) =>
      createTrackingClickHandler(
        createSelectSectionEvent({
          clickitemname: section.name,
          clickitemurl: section.url,
          clickitemblock: letter ?? '',
        }),
        () => updateCookies(section),
      ),
    [updateCookies],
  );

  return (
    <MySectionPageGroup sectionsToRender={templateData.subSections} createSelectSectionHandler={createSelectSectionHandler} />
  );
}

export default MySectionPageTemplate;
