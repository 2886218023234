export type SearchPageTemplateData = {
  template: 'search';
  query: string;
  sortBy: string;
  sectionUrl: string;
  resultCount: number;
  searchBoxLabel: string;
  searchBoxPlaceholder: string;
};

export function createSearchPageTemplateData({
  query = '',
  sortBy = '',
  sectionUrl = '',
  resultCount = 0,
  searchBoxLabel = '',
  searchBoxPlaceholder = '',
}: Partial<Omit<SearchPageTemplateData, 'template'>> = {}): SearchPageTemplateData {
  return {
    template: 'search',
    query,
    sortBy,
    sectionUrl,
    resultCount,
    searchBoxLabel,
    searchBoxPlaceholder,
  };
}
