import type { ReactElement } from 'react';

import styles from './piped-container.module.scss';

type PipedContainerProps = {
  children: ReactElement;
  leftPipe: ReactElement | null;
  rightPipe: ReactElement | null;
};

export function PipedContainer({ children, leftPipe = null, rightPipe = null }: PipedContainerProps) {
  if (!leftPipe && !rightPipe) {
    return children;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
      {!!leftPipe && <div className={styles.leftPipe}>{leftPipe}</div>}
      {!!rightPipe && <div className={styles.rightPipe}>{rightPipe}</div>}
    </div>
  );
}

export default PipedContainer;
