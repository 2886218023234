import { createUrl } from '@hubcms/utils-url';
import { error } from '@hubcms/utils-monitoring';

export async function getNextPageCookData(nextPageSearchParams: string): Promise<unknown> {
  const url = createUrl(`/api/cook${window.location.pathname}`);
  url.search = nextPageSearchParams;

  try {
    const response = await fetch(url);
    const json = await response.json();
    return json.data;
  } catch (e) {
    error(e);
    return {};
  }
}
