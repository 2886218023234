import { type CookData, isMySectionPage } from '@hubcms/domain-cook';
import { createMySectionTemplateData, createSectionData } from '@hubcms/domain-section';
import { mapToGroupOptionsRecord, getStoryblocks } from '@hubcms/utils-storyblock';
import { getMySectionOverviewConfiguration } from '@hubcms/utils-my-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { flattenFilterAndMapSections } from '../../utils/flattenFilterAndMapSections';
import { validateMySectionLayout } from '../../utils/validateMySectionLayout';
import type { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';

export function createMySectionPage(cookData: CookData, teaserPropsOptions: TeaserPropsOptions): MapSectionPageReturnType {
  if (!isMySectionPage(cookData)) {
    return null;
  }
  const { sectionParams } = cookData;

  const sectionData = createSectionData();

  sectionData.storyblocks = getStoryblocks(cookData.context.rootGroup as never, {
    sectionParams,
    teaserPropsOptions,
    areaNames: ['mySection'],
    hasNoAds: true,
  });

  const sectionsToExclude =
    sectionParams['mysection.subsectionsexcluded']
      ?.split(',')
      .map(v => v.trim())
      .filter(v => v !== '') ?? [];
  const overviewConfiguration = getMySectionOverviewConfiguration(sectionParams);

  const groupOptions = mapToGroupOptionsRecord(cookData.context.rootGroup.groupOptions);

  const templateData = createMySectionTemplateData({
    ...overviewConfiguration,
    layout: validateMySectionLayout(groupOptions.layout),
    subSections: flattenFilterAndMapSections(cookData.subsections, sectionsToExclude, overviewConfiguration.identifierParameter),
    header: groupOptions.header,
    pageIntro: groupOptions.pageIntro,
  });

  return {
    sectionData,
    templateData,
  };
}
