import type { SelectSectionEvent } from '@hubcms/domain-tracking';

export type SelectSectionData = Omit<SelectSectionEvent, 'event'>;

export function createSelectSectionEvent(data: SelectSectionData): SelectSectionEvent {
  return {
    event: 'select_section',
    clickitemblock: data.clickitemblock,
    clickitemname: data.clickitemname,
    clickitemurl: data.clickitemurl,
  };
}
