import type { ReactElement } from 'react';

import { Heading } from '@mediahuis/chameleon-react';
import { PipeAd } from '@hubcms/feature-ads';
import { MySectionPage } from '@hubcms/feature-mysectionpage';
import type { CookData } from '@hubcms/domain-cook';
import { Container, PipedContainer } from '@hubcms/ui-container';
import { mapSectionPage } from '@hubcms/utils-section';
import { StoryblockList } from '@hubcms/feature-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';

import TagPage from '../../components/TagPage';
import SearchPage from '../../components/SearchPage';
import MHLUSection from '../../components/Section/Pages/MHLUSection';
import AuthorPage from '../../components/AuthorPage';
import MostRecentPage from '../../components/MostRecent';
import { PageLayout } from '../../components/PageLayout';

type SectionProps = {
  data: CookData;
  teaserPropsOptions: TeaserPropsOptions;
};

const Section = ({ data, teaserPropsOptions }: SectionProps): ReactElement => {
  const sectionPage = mapSectionPage(data, teaserPropsOptions);

  if (!sectionPage) {
    throw Error(`No valid section data for ${data.context?.__typename}`);
  }
  const { sectionData, templateData } = sectionPage;

  if (templateData?.template === 'tag') {
    return (
      <PageLayout hasLeadingSpaceIfNoLeadingAd={true} leadingAdFormat={sectionData.leadingAdFormat}>
        <TagPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />
      </PageLayout>
    );
  }

  if (templateData?.template === 'search') {
    return (
      <PageLayout hasLeadingSpaceIfNoLeadingAd={true} leadingAdFormat={sectionData.leadingAdFormat}>
        <SearchPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />
      </PageLayout>
    );
  }

  if (templateData?.template === 'author') {
    return (
      <PageLayout hasLeadingSpaceIfNoLeadingAd={true} leadingAdFormat={sectionData.leadingAdFormat}>
        <AuthorPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />
      </PageLayout>
    );
  }

  if (templateData?.template === 'most-recent') {
    return (
      <PageLayout hasLeadingSpaceIfNoLeadingAd={true} leadingAdFormat={sectionData.leadingAdFormat}>
        <MostRecentPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />
      </PageLayout>
    );
  }

  if (templateData?.template === 'my-section') {
    return (
      <PageLayout hasLeadingSpaceIfNoLeadingAd={true} leadingAdFormat={sectionData.leadingAdFormat}>
        <MySectionPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />
      </PageLayout>
    );
  }

  if (templateData?.template === 'mhlu-section') {
    return (
      <PageLayout hasLeadingSpaceIfNoLeadingAd={true} leadingAdFormat={sectionData.leadingAdFormat}>
        <MHLUSection sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />
      </PageLayout>
    );
  }

  const leftPipeAd = sectionData.leftPipeAdFormat ? <PipeAd adFormat={sectionData.leftPipeAdFormat} adSlotPrefix="left" /> : null;
  const rightPipeAd = sectionData.rightPipeAdFormat ? (
    <PipeAd adFormat={sectionData.rightPipeAdFormat} adSlotPrefix="right" />
  ) : null;

  // return a default section page
  return (
    <PageLayout hasLeadingSpaceIfNoLeadingAd={{ xs: false, md: true }} leadingAdFormat={sectionData.leadingAdFormat}>
      <PipedContainer leftPipe={leftPipeAd} rightPipe={rightPipeAd}>
        <>
          {sectionData.noContentMessage && (
            <Container>
              <Heading level={4} size="md">
                {sectionData.noContentMessage}
              </Heading>
            </Container>
          )}
          {sectionData.storyblocks.length > 0 && (
            <StoryblockList
              storyblocks={sectionData.storyblocks}
              adPositions={sectionData.storyblockAds.positions}
              adFormats={sectionData.storyblockAds.formats}
              teaserPropsOptions={teaserPropsOptions}
            />
          )}
        </>
      </PipedContainer>
    </PageLayout>
  );
};
export default Section;
