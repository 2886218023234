import type { ViewSearchResultsEvent } from '@hubcms/domain-tracking';

export type ViewSearchResultsData = Omit<ViewSearchResultsEvent, 'event'>;

export function createViewSearchResultsEvent(data: ViewSearchResultsData): ViewSearchResultsEvent {
  return {
    event: 'view_search_results',
    searchresults: data.searchresults,
    searchterm: data.searchterm,
  };
}
