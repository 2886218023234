import { isTeaserDateShownConfig } from '@hubcms/brand';
import type { PageType } from '@hubcms/domain-page';

export function getIsTeaserDateShown(pageType: PageType): boolean {
  if (isTeaserDateShownConfig.has(pageType)) {
    return !!isTeaserDateShownConfig.get(pageType);
  }

  if (isTeaserDateShownConfig.has('default')) {
    return !!isTeaserDateShownConfig.get('default');
  }

  return false;
}
