export function getNextPageNumberAndCount({
  initialAmount,
  pageSize,
  pageNumber,
  offset,
}: {
  initialAmount: number;
  pageSize: number;
  pageNumber: number;
  offset: number;
}) {
  const nextPageNumber = pageNumber + 1;
  const requiredAmount = initialAmount + nextPageNumber * pageSize;
  const count = Math.min(pageSize, Math.max(0, requiredAmount - offset));

  if (count === 0) {
    return getNextPageNumberAndCount({
      initialAmount,
      pageSize,
      pageNumber: nextPageNumber,
      offset,
    });
  }

  return { nextPageNumber, count };
}
