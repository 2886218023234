import type { MhluSectionTemplateData } from '@hubcms/domain-section';
import type { TeaserData } from '@hubcms/domain-teaser';
import { TeaserGrid, TeaserGridExtensionsProvider } from '@hubcms/ui-teaser-grid';
import { useNavigation } from '@hubcms/data-access-navigation';
import { Subnav } from '@hubcms/feature-navigation';

import { sectionGridExtensions } from './sectionGridExtensions';
import { useSectionPageGroup } from './useSectionPageGroup';
import styles from './section.module.scss';

type MHLUSectionOpeningProps = {
  templateData: MhluSectionTemplateData;
  articles: TeaserData[];
};

function MHLUSectionOpening({ templateData, articles }: MHLUSectionOpeningProps) {
  const { subnavItems } = useNavigation();
  const teaserGridProps = useSectionPageGroup(
    subnavItems.length === 0 ? templateData.groupTitle : '',
    templateData.asideRecirculationStoryblocks,
    {
      groupArea: articles,
    },
  );

  return (
    <>
      {subnavItems.length > 0 && (
        <Subnav testId="header-desktop-subnav" className={styles.subnav} title={templateData.groupTitle} items={subnavItems} />
      )}
      <TeaserGridExtensionsProvider initialExtensions={sectionGridExtensions}>
        <TeaserGrid {...teaserGridProps} />
      </TeaserGridExtensionsProvider>
    </>
  );
}

export default MHLUSectionOpening;
