import type { CookData, MostRecentArticles } from '@hubcms/domain-cook';
import { createSectionData, createMostRecentTemplateData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { getPageDataFromExtendedDataSource } from '@hubcms/utils-pagination';
import { getIsTeaserDateShown } from '@hubcms/utils-teaser';

import { buildPagination } from '../../utils/buildPagination';
import { getArticles } from '../../utils/getArticles';
import type { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';

export function createMostRecentPage(cookData: CookData, teaserPropsOptions: TeaserPropsOptions): MapSectionPageReturnType {
  const pagedArticlesData = buildMostRecentPagedArticleData(cookData, teaserPropsOptions);
  const sectionData = createSectionData({ pagedArticlesData });

  const templateData = createMostRecentTemplateData({ title: cookData.resolution.section.name });

  return {
    sectionData,
    templateData,
  };
}

function buildMostRecentPagedArticleData(cookData: CookData, teaserPropsOptions: TeaserPropsOptions) {
  const { sectionParams } = cookData;
  const initialItems = getArticles(cookData.mostRecentArticles?.items, teaserPropsOptions);
  const pagedArticlesData = buildPagination<CookData>(sectionParams, {
    initialItems,
    initialPageSizeKey: 'section.pagination.webInitialAmount',
    extraPageSizeKey: 'section.pagination.webExtraAmount',
    total: cookData.mostRecentArticles?.total,
    adFormatsKey: 'b2b.section.default.ads.adformat',
    adPageSkipSequenceKey: 'b2b.section.default.ads.pageskip',
    canShowEmptyListAd: true,
  });

  pagedArticlesData.initialAmount = initialItems.length;

  pagedArticlesData.teaserHasDateTime = getIsTeaserDateShown('most-recent');
  pagedArticlesData.getPageDataFromResponse = responseData => {
    const pageData = getPageDataFromExtendedDataSource(responseData.mostRecentArticles as MostRecentArticles);
    return {
      pageableItems: getArticles(pageData.pageableItems, teaserPropsOptions),
      hasMore: pageData.hasMore,
    };
  };

  return pagedArticlesData;
}
