import { type PropsWithChildren, useState } from 'react';

import type { TeaserGridExtensions } from '@hubcms/domain-teaser-grid';

import { TeaserGridExtensionsContext } from './context';

function TeaserGridExtensionsProvider({
  initialExtensions,
  children,
}: PropsWithChildren<{ initialExtensions: TeaserGridExtensions }>) {
  const [extensions, setExtensions] = useState<TeaserGridExtensions>(initialExtensions);

  const addExtensions = (e: TeaserGridExtensions) => {
    setExtensions(current => ({ ...current, ...e }));
  };

  return (
    <TeaserGridExtensionsContext.Provider value={{ extensions, addExtensions }}>{children}</TeaserGridExtensionsContext.Provider>
  );
}

export default TeaserGridExtensionsProvider;
