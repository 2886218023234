type SubSection = {
  name: string;
  url: string;
  identifierValue: string;
};

export type MySectionTemplateData = {
  template: 'my-section';
  overviewPageUrl: string;
  storeMySectionSelectionInCookie: boolean;
  identifierParameter: string;
  identifierLabel: string;
  mySectionType: string;
  layout: 'tiles' | 'list';
  subSections: SubSection[];
  header: string;
  pageIntro: string;
};

export function createMySectionTemplateData({
  layout = 'list',
  overviewPageUrl = '',
  storeMySectionSelectionInCookie = false,
  identifierParameter = '',
  mySectionType = '',
  identifierLabel = '',
  subSections = [],
  header = '',
  pageIntro = '',
}: Partial<Omit<MySectionTemplateData, 'template'>> = {}): MySectionTemplateData {
  return {
    template: 'my-section',
    layout,
    overviewPageUrl,
    storeMySectionSelectionInCookie,
    identifierParameter,
    identifierLabel,
    mySectionType,
    subSections,
    header,
    pageIntro,
  };
}
