import { BrandedHeading, LinkText } from '@mediahuis/chameleon-react';
import type { MySectionPageProps } from '../domain/my-section-page-props';
import { groupSectionsByLetter } from '../util/groupSectionsByLetter';
import styles from './my-section-page-list.module.scss';

export function MySectionPageList({ sectionsToRender, createSelectSectionHandler }: MySectionPageProps) {
  const groupedSections = groupSectionsByLetter(sectionsToRender);

  return (
    <div className={styles.groupListWrapper}>
      <ol data-testid="mysectionpage-list">
        {Object.entries(groupedSections).map(([letter, sections]) => (
          <li key={letter} className={styles.listGroup} data-testid="mysectionpage-list-group">
            <BrandedHeading size="sm" as="h4" className={styles.groupHeader}>
              {letter}
            </BrandedHeading>
            <ol>
              {sections.map(section => (
                <li key={section.name} className={styles.listGroupItem} data-testid="mysectionpage-list-group-item">
                  <LinkText
                    onClick={createSelectSectionHandler({
                      section,
                      letter,
                    })}
                    href={section.url}
                  >
                    {section.name}
                  </LinkText>
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </div>
  );
}
