import { useState, useCallback } from 'react';

import type { PageData } from '../domain/page-data';
import { getNextPageNumberAndCount } from '../utils/getNextPageNumberAndCount';
import { getNextPageCookData } from '../utils/getNextPageCookData';
import { buildDefaultPageSearchParams } from '../utils/buildDefaultSearchParams';

type UsePagerOptions<PageableArrayItem> = {
  pageSize: number;
  initialItems: PageableArrayItem[];
  initialHasMore: boolean;
  initialAmount?: number;
  secondPageSearchParams?: string;
  getNextPageSearchParams?: (response: unknown, offset: number, count: number) => string;
  getPageDataFromResponse: (response: unknown) => PageData<PageableArrayItem>;
};

type UsePagerResponse<PageableArrayItem> = {
  items: PageableArrayItem[];
  loadPage: () => void;
  isLoading: boolean;
  hasMore: boolean;
  pageNumber: number;
};

export function usePager<PageableArrayItem = unknown>({
  pageSize,
  initialItems,
  initialHasMore,
  initialAmount = initialItems.length,
  secondPageSearchParams = '',
  getNextPageSearchParams = buildDefaultPageSearchParams,
  getPageDataFromResponse,
}: UsePagerOptions<PageableArrayItem>): UsePagerResponse<PageableArrayItem> {
  // Initial offset and count calculation
  const initialOffset = initialItems.length;
  const { count: initialCount } = getNextPageNumberAndCount({ initialAmount, pageSize, pageNumber: 0, offset: initialOffset });

  // Set up states
  const [pageNumber, setPageNumber] = useState(0);
  const [nextPageSearchParams, setNextPageSearchParams] = useState(
    secondPageSearchParams || getNextPageSearchParams(undefined, initialOffset, initialCount),
  );

  const [items, setItems] = useState(initialItems);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(initialHasMore);

  const loadPage = useCallback(async () => {
    if (isLoading || !hasMore) {
      return;
    }

    setIsLoading(true);

    const data = await getNextPageCookData(nextPageSearchParams);
    const newPageData = getPageDataFromResponse(data);
    const newOffset = items.length + newPageData.pageableItems.length;
    const { count: newCount, nextPageNumber } = getNextPageNumberAndCount({
      initialAmount,
      pageSize,
      pageNumber,
      offset: newOffset,
    });

    if (newPageData) {
      setItems([...items, ...newPageData.pageableItems]);
      setHasMore(newPageData.hasMore);
    }

    setIsLoading(false);
    setPageNumber(nextPageNumber);
    setNextPageSearchParams(getNextPageSearchParams(data, newOffset, newCount));
  }, [
    isLoading,
    hasMore,
    pageNumber,
    nextPageSearchParams,
    items,
    initialAmount,
    pageSize,
    getPageDataFromResponse,
    getNextPageSearchParams,
  ]);

  return {
    items,
    loadPage,
    isLoading,
    hasMore,
    pageNumber,
  };
}
