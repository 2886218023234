import { Paragraph } from '@mediahuis/chameleon-react';
import { StoryblockList } from '@hubcms/feature-storyblock';
import { Container } from '@hubcms/ui-container';
import type { MySectionTemplateData, SectionData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { MySectionHeader, mySectionHeaderSize } from '@hubcms/brand';

import MySectionPageTemplate from './MySectionPageTemplate';
import styles from './my-section-page.module.scss';

type MySectionPageProps = {
  sectionData: SectionData;
  templateData: MySectionTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

export function MySectionPage({ sectionData, templateData, teaserPropsOptions }: MySectionPageProps) {
  const { header, pageIntro } = templateData;
  return (
    <Container fullWidthSm fullWidthMd>
      <div className={styles.content}>
        {(header || pageIntro) && (
          <div className={styles.headerContainer}>
            {header && <MySectionHeader size={mySectionHeaderSize}>{header}</MySectionHeader>}
            {pageIntro && <Paragraph>{pageIntro}</Paragraph>}
          </div>
        )}
        {sectionData.storyblocks.length > 0 && (
          <StoryblockList storyblocks={sectionData.storyblocks} teaserPropsOptions={teaserPropsOptions} />
        )}
      </div>
      <MySectionPageTemplate templateData={templateData} />
    </Container>
  );
}
