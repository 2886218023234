import type { TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserDataOrList } from '@hubcms/domain-teaser';

export type MhluSectionTemplateData = {
  template: 'mhlu-section';
  deskedTeaserData: TeaserDataOrList[];
  groupTitle: string;
  asideRecirculationStoryblocks: TStoryblock[];
};

export function createMhluSectionTemplateData({
  groupTitle = '',
  deskedTeaserData = [],
  asideRecirculationStoryblocks = [],
}: Partial<Omit<MhluSectionTemplateData, 'template'>> = {}): MhluSectionTemplateData {
  return {
    template: 'mhlu-section',
    groupTitle,
    deskedTeaserData,
    asideRecirculationStoryblocks,
  };
}
