import { type TeaserFragment, createTeaserFragment } from '@hubcms/domain-cook';
import { type ArticleTeaserData, type TeaserPropsOptions, isArticleTeaserData } from '@hubcms/domain-teaser';
import { mapTeaserData } from '@hubcms/utils-teaser';

export function getArticles(
  teaserContents: Array<TeaserFragment['content']> = [],
  teaserPropsOptions: TeaserPropsOptions,
): ArticleTeaserData[] {
  return teaserContents
    .map(content => mapTeaserData(createTeaserFragment({ content }), teaserPropsOptions))
    .filter(isArticleTeaserData);
}
