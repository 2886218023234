import type { CSSProperties } from 'react';

import type { TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { Ad } from '@hubcms/feature-ads';

import { AsideRecirculation } from '../AsideRecirculation';

import styles from './infinite-article-list.module.scss';

type AsideBlockProps = {
  adFormat: string | null;
  storyblocks: TStoryblock[];
  style: CSSProperties;
  teaserPropsOptions: TeaserPropsOptions;
  adClassName: string;
};
export function AsideBlock({ adFormat, adClassName, storyblocks, style, teaserPropsOptions }: AsideBlockProps) {
  const shouldRenderAdInBetween = !!adFormat;
  const shouldRenderRecirculationInBetween = storyblocks.length > 0;

  if (!shouldRenderAdInBetween && !shouldRenderRecirculationInBetween) {
    return null;
  }

  return (
    <div style={style} className={styles.sideContent}>
      <AsideRecirculation
        storyblocks={storyblocks}
        teaserPropsOptions={teaserPropsOptions}
        className={styles.asideRecirculation}
      />
      {shouldRenderAdInBetween && (
        <Ad
          isSticky
          adFormat={adFormat}
          adStickyTop="var(--scale-7)"
          adSlot="b"
          adSlotPrefix="recirculation"
          className={adClassName}
        />
      )}
    </div>
  );
}
